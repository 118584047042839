<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Generali" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].attribute_BUID_value.label"
                        vid="attribute_BUID_value"
                        :label="beForm[rep].attribute_BUID_value.label"
                        v-model="form[rep].attribute_BUID_value"
                        placeholder="Inserisci un codice"
                        :rules="{ required: true }"
                        readonly
                      />
                    </div>
                    <div class="form-group col-md-3" v-if="isPhisical">
                      <base-input
                        :name="beForm[rep].attribute_NAME_value.label"
                        vid="attribute_NAME_value"
                        :label="beForm[rep].attribute_NAME_value.label"
                        v-model="form[rep].attribute_NAME_value"
                        placeholder="Inserisci un nome"
                        :rules="{ required: true }"
                      />
                    </div>
                    <div class="form-group col-md-3" v-if="isPhisical">
                      <base-input
                        :name="beForm[rep].attribute_SURN_value.label"
                        vid="attribute_SURN_value"
                        :label="beForm[rep].attribute_SURN_value.label"
                        v-model="form[rep].attribute_SURN_value"
                        placeholder="Inserisci un cognome"
                        :rules="{ required: true }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].attribute_NINO_value.label"
                        vid="attribute_NINO_value"
                        :label="beForm[rep].attribute_NINO_value.label"
                        v-model="form[rep].attribute_NINO_value"
                        placeholder="Inserisci un codice fiscale"
                        :rules="getRules('attribute_NINO_value')"
                        textcase="upper"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        :name="beForm[rep].attribute_TYPBROK_value.label"
                        vid="attribute_TYPBROK_value"
                        :label="beForm[rep].attribute_TYPBROK_value.label"
                        v-model="form[rep].attribute_TYPBROK_value"
                        :options="opt_brokers"
                        :rules="getRules('attribute_TYPBROK_value')"
                      />
                    </div>
                    <div class="form-group col-md-3" v-if="!isPhisical">
                      <base-input
                        :name="beForm[rep].attribute_CNAM_value.label"
                        vid="attribute_CNAM_value"
                        :label="beForm[rep].attribute_CNAM_value.label"
                        v-model="form[rep].attribute_CNAM_value"
                        placeholder="Inserisci una ragione sociale"
                        maxlength="65000"
                        :rules="{ required: true }"
                      />
                    </div>
                    <div class="form-group col-md-3" v-if="!isPhisical">
                      <base-input
                        :name="beForm[rep].attribute_CREG_value.label"
                        vid="attribute_CREG_value"
                        :label="beForm[rep].attribute_CREG_value.label"
                        v-model="form[rep].attribute_CREG_value"
                        placeholder="Inserisci una PIVA"
                        :rules="{ required: true }"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <address-book-form
                v-model="address_book_form"
                :beECForm="beForm[addrBook]"
                :repository="addrBook"
              ></address-book-form>
              <correspondence-form
                v-model="correspondence_form"
                :beECForm="beForm[cor]"
                :repository="cor"
              >
              </correspondence-form>
              <b-card
                header="Altre Informazioni"
                header-tag="header"
                class="mt-1"
              >
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-currency
                        :name="beForm[rep].attribute_WHT_value.label"
                        vid="attribute_WHT_value"
                        :label="beForm[rep].attribute_WHT_value.label"
                        v-model="form[rep].attribute_WHT_value"
                        :options="{
                          locale: 'it-IT',
                          currencyDisplay: 'percent',
                          precision: 2,
                        }"
                        placeholder="Inserisci ritenuta"
                        :rules="getRules('attribute_WHT_value')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-currency
                        :name="beForm[rep].attribute_FCPR_value.label"
                        vid="attribute_FCPR_value"
                        :label="beForm[rep].attribute_FCPR_value.label"
                        v-model="form[rep].attribute_FCPR_value"
                        :options="{
                          locale: 'it-IT',
                          currencyDisplay: 'percent',
                          precision: 2,
                        }"
                        placeholder="Inserisci percentuale"
                        :rules="getRules('attribute_FCPR_value')"
                      />
                    </div>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].attribute_BAHO_value.label"
                        vid="attribute_BAHO_value"
                        :label="beForm[rep].attribute_BAHO_value.label"
                        v-model="form[rep].attribute_BAHO_value"
                        placeholder="Inserisci intestazione conto corrente"
                        :rules="getRules('attribute_BAHO_value')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].attribute_BANK_value.label"
                        vid="attribute_BANK_value"
                        :label="beForm[rep].attribute_BANK_value.label"
                        v-model="form[rep].attribute_BANK_value"
                        placeholder="Inserisci una banca"
                        :rules="getRules('attribute_BANK_value')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].attribute_BABR_value.label"
                        vid="attribute_BABR_value"
                        :label="beForm[rep].attribute_BABR_value.label"
                        v-model="form[rep].attribute_BABR_value"
                        placeholder="Inserisci una filiale"
                        :rules="getRules('attribute_BABR_value')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].attribute_IBAN_value.label"
                        vid="attribute_IBAN_value"
                        :label="beForm[rep].attribute_IBAN_value.label"
                        v-model="form[rep].attribute_IBAN_value"
                        placeholder="Inserisci un IBAN"
                        :rules="getRules('attribute_IBAN_value')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card header="Note" header-tag="header" class="mt-1">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-radio
                        vid="attribute_REDE_value"
                        :name="beForm[rep].attribute_REDE_value.label"
                        label="Quietanzamento: includi sospesi"
                        v-model="form[rep].attribute_REDE_value"
                        :options="
                          Object.values(
                            beForm[rep].attribute_REDE_value.options
                          ).map((o) => {
                            return { value: o.value, text: o.text };
                          })
                        "
                        :rules="getRules('attribute_REDE_value')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-radio
                        vid="attribute_REPE_value"
                        :name="beForm[rep].attribute_REPE_value.label"
                        label="Quietanzamento: includi arretrati"
                        v-model="form[rep].attribute_REPE_value"
                        :options="
                          Object.values(
                            beForm[rep].attribute_REPE_value.options
                          ).map((o) => {
                            return { value: o.value, text: o.text };
                          })
                        "
                        :rules="getRules('attribute_REPE_value')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-radio
                        vid="attribute_READ_value"
                        :name="beForm[rep].attribute_READ_value.label"
                        :label="beForm[rep].attribute_READ_value.label"
                        v-model="form[rep].attribute_READ_value"
                        :options="
                          Object.values(
                            beForm[rep].attribute_READ_value.options
                          ).map((o) => {
                            return { value: o.value, text: o.text };
                          })
                        "
                        :rules="getRules('attribute_READ_value')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-radio
                        vid="attribute_SEEX_value"
                        :name="beForm[rep].attribute_SEEX_value.label"
                        :label="beForm[rep].attribute_SEEX_value.label"
                        v-model="form[rep].attribute_SEEX_value"
                        :options="
                          Object.values(
                            beForm[rep].attribute_SEEX_value.options
                          ).map((o) => {
                            return { value: o.value, text: o.text };
                          })
                        "
                        :rules="getRules('attribute_SEEX_value')"
                      />
                    </div>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-4">
                      <base-textarea
                        vid="attribute_FINO_value"
                        :name="beForm[rep].attribute_FINO_value.label"
                        :label="beForm[rep].attribute_FINO_value.label"
                        v-model="form[rep].attribute_FINO_value"
                        placeholder="Inserisci nota su quietanze"
                        :rules="getRules('attribute_FINO_value')"
                      ></base-textarea>
                    </div>
                    <div class="form-group col-md-4">
                      <base-textarea
                        :name="beForm[rep].attribute_INNO_value.label"
                        vid="attribute_INNO_value"
                        label="Nota su fattura proforma"
                        v-model="form[rep].attribute_INNO_value"
                        placeholder="Inserisci nota su fattura proforma"
                        :rules="getRules('attribute_INNO_value')"
                      ></base-textarea>
                    </div>
                    <div class="form-group col-md-4">
                      <base-textarea
                        :name="beForm[rep].attribute_RENO_value.label"
                        vid="attribute_RENO_value"
                        :label="beForm[rep].attribute_RENO_value.label"
                        v-model="form[rep].attribute_RENO_value"
                        placeholder="Inserisci nota su rendiconto"
                        :rules="getRules('attribute_RENO_value')"
                      ></base-textarea>
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <!-- -->
              <b-card
                header="Flusso contabilità fiscale"
                header-tag="header"
                class="mt-2"
                v-if="canVerb('fattutto', 'config')"
              >
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-checkbox
                        :name="beForm.fattutto.active.label"
                        vid="fattutto_active"
                        groupLabel=""
                        :label="beForm.fattutto.active.label"
                        v-model="form.fattutto.active"
                        :chk_val="true"
                        :unchk_val="false"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm.fattutto.code.label"
                        vid="fattutto_code"
                        :label="beForm.fattutto.code.label"
                        v-model="form.fattutto.code"
                        placeholder="Inserisci un codice"
                        textcase="upper"
                        :rules="getCustomRules('fattutto_code')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-checkbox
                        name="registry_transfer"
                        groupLabel=""
                        :label="`Trasferire l'anagrafica al sistema di contabilità fiscale`"
                        v-model="form.fattutto.registry_transfer"
                        :disabled="true"
                      /></div
                  ></b-row>
                </b-card-text>
              </b-card>
              <!-- -->
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseCheckbox from "@/components/form/BaseCheckbox";
import BaseInput from "@/components/form/BaseInput";
import BaseCurrency from "@/components/form/BaseCurrency";
import BaseRadio from "@/components/form/BaseRadio";
import AddressBookForm from "@/components/form/AddressBookForm";
import CorrespondenceForm from "@/components/form/CorrespondenceForm";
import BaseTextarea from "@/components/form/BaseTextarea";
import AddressBookCorrespondenceMixin from "@/mixins/AddressBookCorrespondenceMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import ShowMixin from "@/mixins/ShowMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import { mapGetters } from "vuex";
import BaseSelect from "@/components/form/BaseSelect";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { setFormValues } from "@/utils/forms";
import { canVerb } from "@/utils/permissions";

export default {
  mixins: [
    ShowMixin,
    FormMixin,
    ShortcutMixin,
    AddressBookCorrespondenceMixin,
    StorageGetterMixin,
  ],
  data() {
    return {
      repository: "broker",
      id: this.$route.params.id,
      item: this.$route.params.item,
      correspondence_id: null,
      address_book_id: null,
      details: {},
      form: {
        defaultCountryName: "Italia",
        home_province: null,
        optHomeCountries: [],
        optHomeProvinces: [],
        optHomeCouncils: [],
        broker: {
          status_broker: 0,
          attribute_NAME_value: null,
          attribute_SURN_value: null,
          attribute_BUID_value: null,
          attribute_BABR_value: null,
          attribute_CREG_value: null,
          attribute_NINO_value: null,
          attribute_TYPBROK_value: null,
          attribute_REDE_value: null,
          attribute_SEEX_value: null,
          attribute_FCPR_value: null,
          attribute_WHT_value: null,
          attribute_BAHO_value: null,
          attribute_BANK_value: null,
          attribute_IBAN_value: null,
          attribute_REPE_value: null,
          attribute_READ_value: null,
          attribute_FINO_value: null,
          attribute_INNO_value: null,
          attribute_RENO_value: null,
        },
        address_book: {
          status_addressbook: 0,
          attribute_CELL_value: null,
          attribute_FAX_value: null,
          attribute_MAIL_value: null,
          attribute_PEC_value: null,
          attribute_PHON_value: null,
          attribute_WEB_value: null,
          broker: {},
        },
        correspondence: {
          status_correspondence: 0,
          attribute_ADDR_value: null,
          attribute_CITY_value: null,
          attribute_CITY2_value: null,
          attribute_NOR_value: null,
          attribute_NAME2_value: null,
          attribute_ZIPC_value: null,
          broker: {},
        },
        fattutto: {
          code: "",
          active: false,
          registry_transfer: false,
        },
      },
      opt_brokers: [
        { text: "NO", value: "NO" },
        { text: "SI", value: "SI" },
      ],
    };
  },
  components: {
    BaseIcon,
    BaseCheckbox,
    BaseInput,
    BaseCurrency,
    BaseRadio,
    AddressBookForm,
    CorrespondenceForm,
    BaseTextarea,
    BaseSelect,
  },
  methods: {
    canVerb,
    getCustomRules(field) {
      let rules = {};
      if (field === "fattutto_code") {
        if (this.form.fattutto.active) {
          rules["required"] = true;
        } else {
          delete rules["required"];
        }
      }
      return rules;
    },
    onSubmit() {
      switch (this.isPhisical) {
        case true:
          this.$delete(this.form[this.repository], "attribute_CREG_value");
          this.$delete(this.form[this.repository], "attribute_CNAM_value");
          break;
        case false:
          this.$delete(this.form[this.repository], "attribute_SURN_value");
          this.$delete(this.form[this.repository], "attribute_NAME_value");
          break;
      }
      this.isLoading = true;
      this.update(this.repository, this.id)
        .then(() => {
          let promises = [];
          if (this.address_book_id) {
            promises.push(
              this.editSection(
                this.addrBook,
                this.address_book_id,
                this.repository
              )
            );
          } else {
            promises.push(
              this.storeSection(this.addrBook, this.id, this.repository)
            );
          }
          if (this.correspondence_id) {
            promises.push(
              this.editSection(
                this.cor,
                this.correspondence_id,
                this.repository
              )
            );
          } else {
            promises.push(
              this.storeSection(this.cor, this.id, this.repository)
            );
          }
          /* */
          /* */
          Promise.all(promises)
            .then(() => {
              this.$store.dispatch("auth/salesmen");
              /* */
              const Repo = RepositoryFactory.get("fattutto");
              const payload = {
                configurable_id: this.id,
                configurable_type: "broker",
                code: this.form.fattutto.code,
                ledger_type: 3, // no ledger_type
                // note: "",
                active: this.form.fattutto.active,
              };
              (this?.details?.id
                ? Repo.update_configuration(this.details.id, payload)
                : Repo.store_configuration(payload)
              )
                .then(() => {
                  this.onSucess("produttore");
                  this.shortcut(
                    "module.PROF.PRCO",
                    null,
                    "#Salesmen",
                    "filterSalesmen"
                  );
                  this.isLoading = false;
                })
                .catch((error) => {
                  let errMsg = this.$getErrorMessage(error);
                  this.$showSnackbar({
                    preset: "error",
                    text: `${errMsg}`,
                  });
                  this.isLoading = false;
                });
              /* */
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
        })
        .catch((error) => {
          this.onFailure(error);
        });
    },
    onSucess(resource) {
      this.$showSnackbar({
        preset: "success",
        text: `Azione completata: ${resource} aggiornato`,
      });
    },
    onFailure(error) {
      let errMsg = this.$getErrorMessage(error);
      this.$showSnackbar({
        preset: "error",
        text: `${errMsg}`,
      });
      this.isLoading = false;
    },
    getForms() {
      this.fetchShowForm(this.repository, this.id).then((response) => {
        console.log("response", response);
        const data = response.data.data?.fattutto_configuration?.[0] ?? {};
        // response.data.data.fattutto_configuration[0]
        this.details = data;
        this.fetchEditForm(this.repository, this.id).then(() => {
          let promises = [];
          promises.push(this.fetchAddressBook());
          promises.push(this.fetchCorrespondences());
          Promise.all(promises)
            .then(() => {
              this.beForm.fattutto = {};
              this.beForm["fattutto"].active = {
                label: `Produttore attivo all'esportazione per contabilità fiscale`,
                type: "select",
                value: this.form.fattutto.active,
              };
              this.beForm["fattutto"].code = {
                label: `Codice Produttore per flusso esportazione contabilità fiscale`,
                type: "text",
                value: this.form.fattutto.code,
              };
              if (Object.entries(this.details).length) {
                this.beForm["fattutto"].active.value = Boolean(
                  this.details.active
                );
                this.beForm["fattutto"].code.value = this.details.code;
              }
              this.form["fattutto"] = setFormValues(
                ["active", "code"],
                this.beForm["fattutto"]
              );
              this.isLoading = false;
            })
            .catch((error) => {
              this.onFailure(error);
            });
          // .finally(() => {
          //   this.isLoading = false;
          // });
        });
      });
    },
    ...mapGetters("auth", [
      "countries",
      "provinces",
      "provinceByProvinceId",
      "countries",
      "provinces",
      "councils",
    ]),
  },
  created() {
    /* */
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("councils"))
      resources.push(
        this.$store
          .dispatch(`${path}councils`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (!this.hasLoadedResource("provinces"))
      resources.push(
        this.$store
          .dispatch(`${path}provinces`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (!this.hasLoadedResource("countries")) {
      resources.push(
        this.$store
          .dispatch(`${path}countries`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    }
    if (resources.length) {
      this.getResources(resources, false)
        .then(() => {
          this.getForms();
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
          this.isLoading = false;
        });
    } else {
      this.getForms();
    }
    /* */
  },
  beforeMount() {
    this.isLoading = true;
  },
  computed: {
    rep() {
      return this.repository;
    },
    cor() {
      return "correspondence";
    },
    addrBook() {
      return "address_book";
    },
    isPhisical() {
      return (
        !this.form[this.rep].attribute_CREG_value &&
        !this.form[this.rep].attribute_CNAM_value
      );
    },
  },
};
</script>
